<template>
    <div class="container">
        <qy-top-nav title="评价" />
        <van-form>
            <van-row>
                <van-col span="5">
                    <van-image style="margin: 10px 0px 0px 10px;" fit="fill" width="70" height="70" :src="form.goodsLogo"  />
                </van-col>
                <van-col span="16">
                    <label style="float:right;position: relative;top:25px;right:-28px;z-index: 2005;font-size: 14px;"><van-switch v-model="checked" size="14px" style="position: relative;top:3px;"/>&nbsp;匿名评价</label>
                    <van-field name="commentLevel" label="商品评分">
                        <template #input>
                            <van-rate v-model="form.commentLevel" :size="25" gutter="12" />
                        </template>
                    </van-field>
                </van-col>
            </van-row>
            <van-field v-model="form.comment" rows="2" autosize label="" type="textarea" maxlength="50" placeholder="请输入你对商品的评价..." show-word-limit />
            <van-field name="uploader" label="">
                <template #input>
                    <van-uploader v-model="images" upload-text="添加图片"  :after-read="afterRead"  max-count="3" multiple/>
                </template>
            </van-field>
            <div style="margin: 16px;">
                <van-button round block type="info" @click="submit">提交</van-button>
            </div>
        </van-form>
    </div>
</template>
<script type="text/javascript">
import request from "@/utils/request";
import { Toast } from 'vant';
import qt from "qs";
export default {
    name: "addComment",
    data() {
        return {
            images: [],
            checked:false,
            form: {
                goodsLogo: this.$route.params.goodsLogo,
                orderId: this.$route.params.orderId,
                orderNo: this.$route.params.orderNo,
                shopId: this.$route.params.shopId,
                goodsId: this.$route.params.goodsId,
                goodsInstanceId: this.$route.params.goodsInstanceId,
                comment: "",
                commentLevel: 5,
                commentImg: "",
                hideName:0,//不匿名
            },
        };
    },
    methods: {
        afterRead(file) {
            let url = "/api/common/upload";
            let fd = new FormData;
            fd.append("file", file.file);
            this.$axios.post(url, fd).then((res) => {
                let data = res.data;
                if (data.code == 0) {
                    file.file.url = data.fileName;
                } else {
                    Toast.fail("上传失败");
                }
            }).catch(err => {
                Toast.fail(err);
            });
        },
        submit() {
            let tempArray = [];
            this.images.forEach(file => {
                tempArray.push(file.file.url);
            });
            if (tempArray.length > 0){
                this.form.commentImg = tempArray.join(',');
            }
            this.form.hideName = this.checked ? 1 : 0;
            this.Dialog.confirm({
                message: "确定要提交评价吗?"
            }).then(() => {
                request.post("/api/comment/add", this.form).then(res => {
                    if (res.code == 0) {
                        this.$router.go(-1);
                    } else {
                        Toast.fail(res.msg || '提交失败');
                    }
                });
            })

        },
    }
}
</script>
<style type="text/css" scoped="">
.van-cell {
    display: block;
}
</style>